import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import RequestNewHosting from '../views/RequestNewHosting.vue';
import NewHosting from '../views/NewHosting.vue';
import PageNotFound from '../views/PageNotFound.vue';
import Refresh from '../views/Refresh.vue';
import Admin from '../views/admin';
import Partner from '../views/partner';
import Retail from '../views/retail';
import Cp from '../views/cp';
import store from '../store';

Vue.use(VueRouter);

const { host, pathname } = window.location;

if (typeof window.baseUrl === 'undefined') {
  if (pathname.indexOf('/aud/') === 0) {
    window.baseUrl = 'aud';
  } else if (pathname.indexOf('/usd/') === 0) {
    window.baseUrl = 'usd';
  } else if (pathname.indexOf('/sgd/') === 0) {
    window.baseUrl = 'sgd';
  } else {
    window.baseUrl = '';
  }
}

// eslint-disable-next-line import/no-mutable-exports
let router = null;

if (host.indexOf('manage.cldy.com') !== 0
  && host.indexOf('local.manage.cldy.com') !== 0
  && host.indexOf('staging.manage.cldy.com') !== 0
  && host.indexOf('manage.qoxy.com') !== 0
  && host.indexOf('local.manage.qoxy.com') !== 0
  && host.indexOf('staging.manage.qoxy.com') !== 0
  && host.indexOf('login.cldy.com') !== 0
  && host.indexOf('staging.login.cldy.com') !== 0
  && host.indexOf('cp.gocloudeasy.com') !== 0
  && host.indexOf('staging.cp.gocloudeasy.com') !== 0
  && host.indexOf('login.gocloudeasy.com') !== 0
  && host.indexOf('staging.login.gocloudeasy.com') !== 0) {
  const routes = [
    {
      path: '/',
      name: 'Login',
      component: Login,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/request/new-hosting',
      name: 'RequestNewHosting',
      component: RequestNewHosting,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/new-hosting',
      name: 'NewHosting',
      component: NewHosting,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/refresh',
      name: 'refresh',
      component: Refresh,
    },
    {
      path: '/forgot-password',
      name: 'ForgotPassword',
      component: ForgotPassword,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/reset-password/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/',
      name: 'Retail.Guest',
      component: Retail.Guest,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/guest-cart',
      name: 'Retail.GuestShoppingCart',
      component: Retail.GuestShoppingCart,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/express-checkout/:token',
      name: 'Retail.ExpressCheckout',
      props: true,
      component: Retail.ExpressCheckout,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/express-save-card/:token',
      name: 'Retail.ExpressSaveCardPaypal',
      props: true,
      component: Retail.ExpressSaveCardPaypal,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/express-invoice/:id',
      name: 'Retail.ExpressInvoicePayment',
      props: true,
      component: Retail.ExpressInvoicePayment,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/login',
      name: 'Retail.Login',
      component: Retail.Login,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/forgot-password',
      name: 'Retail.ForgotPassword',
      component: Retail.ForgotPassword,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/reset-password/:token',
      name: 'Retail.ResetPassword',
      component: Retail.ResetPassword,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/:brand/service-transfer/:token',
      name: 'Retail.ServiceTransfer',
      component: Retail.ServiceTransfer,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '*',
      name: '404',
      component: PageNotFound,
    },
    {
      path: '/site/404',
      name: 'Retail404',
      component: Retail.PageNotFound,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/admin',
      component: Admin.Base,
      children: [
        {
          path: 'home',
          name: 'Admin.Home',
          component: Admin.Home,
        },
        {
          path: 'partner/list',
          name: 'Admin.PartnerList',
          component: Admin.PartnerList,
        },
        {
          path: 'partner/client-list',
          name: 'Admin.PartnerClientList',
          component: Admin.PartnerClientList,
        },
        {
          path: 'partner/auth-user-list',
          name: 'Admin.ListAuthUsers',
          component: Admin.ListAuthUsers,
        },
        {
          path: 'partner/add',
          name: 'Admin.PartnerAdd',
          component: Admin.PartnerAdd,
        },
        {
          path: 'partner/detail/:id',
          name: 'Admin.PartnerDetail',
          component: Admin.PartnerDetail,
          props: true,
        },
        {
          path: 'partner/product-member-prices/:id',
          name: 'Admin.PartnerProductMemberPrices',
          component: Admin.PartnerProductMemberPrices,
          props: true,
        },
        {
          path: 'partner/addon-member-prices/:id',
          name: 'Admin.PartnerAddonMemberPrices',
          component: Admin.PartnerAddonMemberPrices,
          props: true,
        },
        {
          path: 'partner/domain-product-member-prices/:id',
          name: 'Admin.PartnerDomainProductMemberPrices',
          component: Admin.PartnerDomainProductMemberPrices,
          props: true,
        },
        {
          path: 'partner/member-only-retail-products/:id',
          name: 'Admin.MemberOnlyRetailProducts',
          component: Admin.MemberOnlyRetailProducts,
          props: true,
        },
        {
          path: 'partner/update-auth-users/:id',
          name: 'Admin.UpdateAuthUsers',
          component: Admin.UpdateAuthUsers,
          props: true,
        },

        {
          path: 'server/list',
          name: 'Admin.ServerList',
          component: Admin.ServerList,
        },
        {
          path: 'server/add',
          name: 'Admin.ServerAdd',
          component: Admin.ServerAdd,
        },
        {
          path: 'server/detail/:id',
          name: 'Admin.ServerDetail',
          component: Admin.ServerDetail,
          props: true,
        },
        {
          path: 'server-group/list',
          name: 'Admin.ServerGroupList',
          component: Admin.ServerGroupList,
        },
        {
          path: 'server-group/add',
          name: 'Admin.ServerGroupAdd',
          component: Admin.ServerGroupAdd,
        },
        {
          path: 'server-group/detail/:id',
          name: 'Admin.ServerGroupDetail',
          component: Admin.ServerGroupDetail,
          props: true,
        },
        {
          path: 'product/list',
          name: 'Admin.ProductList',
          component: Admin.ProductList,
        },
        {
          path: 'product/add',
          name: 'Admin.ProductAdd',
          component: Admin.ProductAdd,
        },
        {
          path: 'product/detail/:id',
          name: 'Admin.ProductDetail',
          component: Admin.ProductDetail,
          props: true,
        },
        {
          path: 'addon/list',
          name: 'Admin.AddonList',
          component: Admin.AddonList,
        },
        {
          path: 'addon/add',
          name: 'Admin.AddonAdd',
          component: Admin.AddonAdd,
        },
        {
          path: 'addon/detail/:id',
          name: 'Admin.AddonDetail',
          component: Admin.AddonDetail,
          props: true,
        },
        {
          path: 'product-type/list',
          name: 'Admin.ProductTypeList',
          component: Admin.ProductTypeList,
        },
        {
          path: 'product-type/add',
          name: 'Admin.ProductTypeAdd',
          component: Admin.ProductTypeAdd,
        },
        {
          path: 'product-type/detail/:id',
          name: 'Admin.ProductTypeDetail',
          component: Admin.ProductTypeDetail,
          props: true,
        },
        {
          path: 'domain-product/list',
          name: 'Admin.DomainProductList',
          component: Admin.DomainProductList,
        },
        {
          path: 'domain-product/add',
          name: 'Admin.DomainProductAdd',
          component: Admin.DomainProductAdd,
        },
        {
          path: 'domain-product/detail/:id',
          name: 'Admin.DomainProductDetail',
          component: Admin.DomainProductDetail,
          props: true,
        },
        {
          path: 'service-manual-provision-queue/list',
          name: 'Admin.ServiceManualProvisionQueueList',
          component: Admin.ServiceManualProvisionQueueList,
        },
        {
          path: 'service-manual-provision-queue/detail/:id',
          name: 'Admin.ServiceManualProvisionQueueDetail',
          component: Admin.ServiceManualProvisionQueueDetail,
          props: true,
        },
        {
          path: 'service/list-active-services',
          name: 'Admin.ListActiveServices',
          component: Admin.ListActiveServices,
        },
        {
          path: 'service/list-migration-services',
          name: 'Admin.ListMigrationServices',
          component: Admin.ListMigrationServices,
        },
        {
          path: 'service/cpanel/:id/update',
          name: 'Admin.ServiceCpanelUpdate',
          component: Admin.ServiceCpanelUpdate,
        },
        {
          path: 'service/cpanel/:id/sync',
          name: 'Admin.ServiceCpanelSync',
          component: Admin.ServiceCpanelSync,
        },
        {
          path: 'service/list-active-domain',
          name: 'Admin.ListActiveDomains',
          component: Admin.ListActiveDomains,
        },
        {
          path: 'service/import-cpanel-service',
          name: 'Admin.ImportCpanelService',
          component: Admin.ImportCpanelService,
        },
        {
          path: 'service/import-domain-service',
          name: 'Admin.ImportDomainService',
          component: Admin.ImportDomainService,
        },
        {
          path: 'service/import-manual-service',
          name: 'Admin.ImportManualService',
          component: Admin.ImportManualService,
        },
        {
          path: 'service/bulk-import-cpanel-service',
          name: 'Admin.BulkImportCpanelService',
          component: Admin.BulkImportCpanelService,
        },
        {
          path: 'service/cpanel-diskusage',
          name: 'Admin.CpanelDiskusage',
          component: Admin.CpanelDiskusage,
        },
        {
          path: 'service/toggle-expired',
          name: 'Admin.ToggleExpired',
          component: Admin.ToggleExpired,
        },
        {
          path: 'service/force-ds-admin-change',
          name: 'Admin.ForceDsDomainAdminChange',
          component: Admin.ForceDsDomainAdminChange,
        },
        {
          path: 'service/zero-year-transfer',
          name: 'Admin.ZeroYearTransfer',
          component: Admin.ZeroYearTransfer,
        },
        {
          path: 'service/zero-year-transfer-sgnic',
          name: 'Admin.ZeroYearTransferSgnic',
          component: Admin.ZeroYearTransferSgnic,
        },
        {
          path: 'service/vendor-inventory',
          name: 'Admin.VendorList',
          component: Admin.VendorList,
        },
        {
          path: 'service/cancel-firstcom-service',
          name: 'Admin.CancelFirstcomService',
          component: Admin.CancelFirstcomService,
        },
        {
          path: 'service/query-domain',
          name: 'Admin.QueryDomainContact',
          component: Admin.QueryDomainContact,
        },
        {
          path: 'service/detail/:id',
          name: 'Admin.ServiceDetail',
          component: Admin.ServiceDetail,
          props: true,
        },
        {
          path: 'domain/detail/:id',
          name: 'Admin.DomainDetail',
          component: Admin.DomainDetail,
          props: true,
        },
        {
          path: 'payment/history',
          name: 'Admin.PartnerPaymentHistory',
          component: Admin.PartnerPaymentHistory,
        },
        {
          path: 'payment/credit-deduction',
          name: 'Admin.PartnerCreditDeduction',
          component: Admin.PartnerCreditDeduction,
        },
        {
          path: 'payment/manual-invoice',
          name: 'Admin.ManualInvoice',
          component: Admin.ManualInvoice,
        },
        {
          path: 'payment/tax-invoice-list',
          name: 'Admin.TaxInvoiceList',
          component: Admin.TaxInvoiceList,
        },
        {
          path: 'affiliate/list',
          name: 'Admin.AffiliateList',
          component: Admin.AffiliateList,
        },
        {
          path: 'affiliate/review/:id',
          name: 'Admin.AffiliateReview',
          component: Admin.AffiliateReview,
        },
        {
          path: 'affiliate-commission/list',
          name: 'Admin.AffiliateCommissionList',
          component: Admin.AffiliateCommissionList,
        },
        {
          path: 'affiliate-commission/withdraw/list',
          name: 'Admin.AffiliateCommissionWithdrawList',
          component: Admin.AffiliateCommissionWithdrawList,
        },
        {
          path: 'affiliate-commission/withdraw/:id',
          name: 'Admin.AffiliateCommissionWithdrawDetail',
          component: Admin.AffiliateCommissionWithdrawDetail,
        },
        {
          path: 'commission/list',
          name: 'Admin.PartnerCommissionList',
          component: Admin.PartnerCommissionList,
        },
        {
          path: 'commission/withdraw/list',
          name: 'Admin.PartnerCommissionWithdrawList',
          component: Admin.PartnerCommissionWithdrawList,
        },
        {
          path: 'commission/withdraw/:id',
          name: 'Admin.PartnerCommissionWithdrawDetail',
          component: Admin.PartnerCommissionWithdrawDetail,
        },
        {
          path: 'discount/list',
          name: 'Admin.DiscountList',
          component: Admin.DiscountList,
        },
        {
          path: 'discount/add',
          name: 'Admin.DiscountAdd',
          component: Admin.DiscountAdd,
        },
        {
          path: 'article/list',
          name: 'Admin.ArticleList',
          component: Admin.ArticleList,
        },
        {
          path: 'article/add',
          name: 'Admin.ArticleAdd',
          component: Admin.ArticleAdd,
        },
        {
          path: 'article/update/:id',
          name: 'Admin.ArticleUpdate',
          component: Admin.ArticleUpdate,
          props: true,
        },
        {
          path: 'article/update-revision/:id',
          name: 'Admin.ArticleRevisionUpdate',
          component: Admin.ArticleRevisionUpdate,
          props: true,
        },
        {
          path: 'article-category/list',
          name: 'Admin.ArticleCategoryList',
          component: Admin.ArticleCategoryList,
        },
        {
          path: 'article-category/add',
          name: 'Admin.ArticleCategoryAdd',
          component: Admin.ArticleCategoryAdd,
        },
        {
          path: 'article-category/update/:id',
          name: 'Admin.ArticleCategoryUpdate',
          component: Admin.ArticleCategoryUpdate,
          props: true,
        },
        {
          path: 'utility/whois',
          name: 'Admin.Whois',
          component: Admin.Whois,
        },
        {
          path: 'utility/notify',
          name: 'Admin.NotifyCustomer',
          component: Admin.NotifyCustomer,
        },
        {
          path: 'finance/stripe-no-matching-payments',
          name: 'Admin.StripeNoMatchingPayments',
          component: Admin.StripeNoMatchingPayments,
        },
        {
          path: 'report/revenue-report',
          name: 'Admin.RevenueReport',
          component: Admin.RevenueReport,
        },
        {
          path: 'report/above-2k-report',
          name: 'Admin.Above2kReport',
          component: Admin.Above2kReport,
        },
        {
          path: 'report/cpanel-server-revenue-report',
          name: 'Admin.CpanelServerRevenueMonthly',
          component: Admin.CpanelServerRevenueMonthly,
        },
        {
          path: 'report/cldy-new-customer-report',
          name: 'Admin.CldyNewCustomerReport',
          component: Admin.CldyNewCustomerReport,
        },
        {
          path: 'domain-audit/resellerclub',
          name: 'Admin.DomainAuditResellerclub',
          component: Admin.DomainAuditResellerclub,
        },
        {
          path: 'domain-audit/dreamscape',
          name: 'Admin.DomainAuditDreamscape',
          component: Admin.DomainAuditDreamscape,
        },
        {
          path: 'domain-audit/webnic',
          name: 'Admin.DomainAuditWebnic',
          component: Admin.DomainAuditWebnic,
        },
        {
          path: 'domain-audit/server-renew-prohibited',
          name: 'Admin.DomainAuditServerRenewProhibited',
          component: Admin.DomainAuditServerRenewProhibited,
        },
        {
          path: 'audit/unpaid-invoices',
          name: 'Admin.AuditUnpaidInvoices',
          component: Admin.AuditUnpaidInvoices,
        },
        {
          path: 'audit/pending-services',
          name: 'Admin.AuditPendingServices',
          component: Admin.AuditPendingServices,
        },
        {
          path: 'audit/pending-domains',
          name: 'Admin.AuditPendingDomains',
          component: Admin.AuditPendingDomains,
        },
        {
          path: 'audit/near-expiry-manual',
          name: 'Admin.AuditNearExpiryManualServices',
          component: Admin.AuditNearExpiryManualServices,
        },
        {
          path: 'audit/not-suspend-cpanel',
          name: 'Admin.AuditNotSuspendCpanel',
          component: Admin.AuditNotSuspendCpanel,
        },
      ],
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/partner',
      component: Partner.Base,
      children: [
        {
          path: 'home',
          name: 'Partner.Home',
          component: Partner.Home,
        },
        {
          path: 'settings',
          name: 'Partner.Settings',
          component: Partner.Settings,
        },
        {
          path: 'profile',
          name: 'Partner.Profile',
          component: Partner.UserProfile,
        },
        {
          path: 'balance/topup',
          name: 'Partner.Topup',
          component: Partner.Topup,
        },
        {
          path: 'payment/history',
          name: 'Partner.TopupHistory',
          component: Partner.TopupHistory,
        },
        {
          path: 'payment/tax-invoice-list',
          name: 'Partner.TaxInvoiceList',
          component: Partner.TaxInvoiceList,
        },
        {
          path: 'balance/list',
          name: 'Partner.BalanceList',
          component: Partner.BalanceList,
        },
        {
          path: 'domain/detail/:id',
          name: 'Partner.DomainDetail',
          component: Partner.DomainDetail,
          props: true,
        },
        {
          path: 'domain/list',
          name: 'Partner.DomainList',
          component: Partner.DomainList,
          props: true,
        },
        {
          path: 'domain/list-archive',
          name: 'Partner.DomainListArchive',
          component: Partner.DomainListArchive,
          props: true,
        },
        {
          path: 'domain/register',
          name: 'Partner.DomainRegister',
          component: Partner.DomainRegister,
          props: true,
        },
        {
          path: 'domain/renew',
          name: 'Partner.DomainRenew',
          component: Partner.DomainRenew,
          props: true,
        },
        {
          path: 'domain/transfer',
          name: 'Partner.DomainTransfer',
          component: Partner.DomainTransfer,
          props: true,
        },
        {
          path: 'domain/request-transfer/:id',
          name: 'Partner.DomainRequestTransfer',
          component: Partner.DomainRequestTransfer,
          props: true,
        },
        {
          path: 'domain/default-contact',
          name: 'Partner.DomainDefaultContact',
          component: Partner.DomainDefaultContact,
          props: true,
        },
        {
          path: 'service/detail/:id',
          name: 'Partner.ServiceDetail',
          component: Partner.ServiceDetail,
          props: true,
        },
        {
          path: 'dns/:id',
          name: 'Partner.DnsEditor',
          component: Partner.DnsEditor,
          props: true,
        },
        {
          path: 'service/list',
          name: 'Partner.ServiceList',
          component: Partner.ServiceList,
          props: true,
        },
        {
          path: 'service/archive-list',
          name: 'Partner.ServiceArchiveList',
          component: Partner.ServiceArchiveList,
          props: true,
        },
        {
          path: 'addon/list',
          name: 'Partner.AddonList',
          component: Partner.AddonList,
          props: true,
        },
        {
          path: 'addon/archive-list',
          name: 'Partner.AddonArchiveList',
          component: Partner.AddonArchiveList,
          props: true,
        },
        {
          path: 'service/register',
          name: 'Partner.ServiceRegister',
          component: Partner.ServiceRegister,
          props: true,
        },
        {
          path: 'service/renew',
          name: 'Partner.ServiceRenew',
          component: Partner.ServiceRenew,
          props: true,
        },
        {
          path: 'reseller/customer/list',
          name: 'Partner.ResellerCustomerList',
          component: Partner.ResellerCustomerList,
        },
        {
          path: 'reseller/customer/detail/:id',
          name: 'Partner.ResellerCustomerDetail',
          component: Partner.ResellerCustomerDetail,
        },
        {
          path: 'reseller/customer/invoice/list',
          name: 'Partner.ResellerCustomerInvoiceList',
          component: Partner.ResellerCustomerInvoiceList,
        },
        {
          path: 'reseller/customer/service/list',
          name: 'Partner.ResellerCustomerServiceList',
          component: Partner.ResellerCustomerServiceList,
        },
        {
          path: 'reseller/customer/domain/list',
          name: 'Partner.ResellerCustomerDomainList',
          component: Partner.ResellerCustomerDomainList,
        },
        {
          path: 'reseller/customer/transfer-queue/list',
          name: 'Partner.ResellerCustomerTransferQueue',
          component: Partner.ResellerCustomerTransferQueue,
        },
        {
          path: 'reseller/customer/invoice/detail/:id',
          name: 'Partner.ResellerCustomerInvoiceDetail',
          component: Partner.ResellerCustomerInvoiceDetail,
        },
        {
          path: 'reseller/customer/product-price',
          name: 'Partner.ResellerProductPrices',
          component: Partner.ResellerProductPrices,
        },
        {
          path: 'reseller/customer/addon-price',
          name: 'Partner.ResellerAddonPrices',
          component: Partner.ResellerAddonPrices,
        },
        {
          path: 'reseller/customer/domain-product-price',
          name: 'Partner.ResellerDomainProductPrices',
          component: Partner.ResellerDomainProductPrices,
        },
        {
          path: 'reseller/customer/product-price/:id',
          name: 'Partner.ResellerMemberProductPrices',
          component: Partner.ResellerMemberProductPrices,
        },
        {
          path: 'reseller/customer/addon-price/:id',
          name: 'Partner.ResellerMemberAddonPrices',
          component: Partner.ResellerMemberAddonPrices,
        },
        {
          path: 'reseller/customer/domain-product-price/:id',
          name: 'Partner.ResellerMemberDomainProductPrices',
          component: Partner.ResellerMemberDomainProductPrices,
        },
        {
          path: 'collaborator/list',
          name: 'Partner.CollaboratorList',
          component: Partner.CollaboratorList,
        },
        {
          path: 'collaborator/:id',
          name: 'Partner.CollaboratorUpdate',
          component: Partner.CollaboratorUpdate,
        },
        {
          path: 'collaborator/service/list',
          name: 'Partner.CollaboratorServiceList',
          component: Partner.CollaboratorServiceList,
        },
        {
          path: 'collaborator/action/list',
          name: 'Partner.CollaboratorActionList',
          component: Partner.CollaboratorActionList,
        },
        {
          path: 'collaborator/new',
          name: 'Partner.CollaboratorNew',
          component: Partner.CollaboratorNew,
        },
        {
          path: 'commission/list',
          name: 'Partner.PartnerCommissionList',
          component: Partner.PartnerCommissionList,
          props: true,
        },
        {
          path: 'commission/withdraw/:id',
          name: 'Partner.PartnerCommissionWithdraw',
          component: Partner.PartnerCommissionWithdraw,
          props: true,
        },
        {
          path: 'commission/yearmonth',
          name: 'Partner.PartnerCommissionListYearMonth',
          component: Partner.PartnerCommissionListYearMonth,
        },
        {
          path: 'commission/yearmonth/:yearmonth',
          name: 'Partner.PartnerCommissionYearMonth',
          component: Partner.PartnerCommissionYearMonth,
          props: true,
        },
        {
          path: 'commission/yearmonth/:yearmonth',
          name: 'Partner.PartnerCommissionYearMonth',
          component: Partner.PartnerCommissionYearMonth,
          props: true,
        },
      ],
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/:brand/',
      component: Retail.Base,
      children: [
        {
          path: 'home',
          name: 'Retail.Home',
          component: Retail.Home,
        },
        {
          path: 'settings',
          name: 'Retail.Settings',
          component: Retail.Settings,
        },
        {
          path: 'profile',
          name: 'Retail.Profile',
          component: Retail.UserProfile,
        },
        {
          path: 'payment/history',
          name: 'Retail.TopupHistory',
          component: Retail.TopupHistory,
        },
        {
          path: 'payment/tax-invoice-list',
          name: 'Retail.TaxInvoiceList',
          component: Retail.TaxInvoiceList,
        },
        {
          path: 'payment/tax-invoice/:id',
          name: 'Retail.TaxInvoice',
          component: Retail.TaxInvoice,
        },
        {
          path: 'vcard/list',
          name: 'Retail.VcardList',
          component: Retail.VcardList,
        },
        {
          path: 'vcard/create',
          name: 'Retail.VcardCreate',
          component: Retail.VcardCreate,
        },
        {
          path: 'domain/detail/:id',
          name: 'Retail.DomainDetail',
          component: Retail.DomainDetail,
          props: true,
        },
        {
          path: 'domain/list',
          name: 'Retail.DomainList',
          component: Retail.DomainList,
          props: true,
        },
        {
          path: 'domain/list-archive',
          name: 'Retail.DomainListArchive',
          component: Retail.DomainListArchive,
          props: true,
        },
        {
          path: 'domain/register',
          name: 'Retail.DomainRegister',
          component: Retail.DomainRegister,
          props: true,
        },
        {
          path: 'domain/renew',
          name: 'Retail.DomainRenew',
          component: Retail.DomainRenew,
          props: true,
        },
        {
          path: 'domain/transfer',
          name: 'Retail.DomainTransfer',
          component: Retail.DomainTransfer,
          props: true,
        },
        {
          path: 'domain/request-transfer/:id',
          name: 'Retail.DomainRequestTransfer',
          component: Retail.DomainRequestTransfer,
          props: true,
        },
        {
          path: 'domain/default-contact',
          name: 'Retail.DomainDefaultContact',
          component: Retail.DomainDefaultContact,
          props: true,
        },
        {
          path: 'service/detail/:id',
          name: 'Retail.ServiceDetail',
          component: Retail.ServiceDetail,
          props: true,
        },
        {
          path: 'dns/:id',
          name: 'Retail.DnsEditor',
          component: Retail.DnsEditor,
          props: true,
        },
        {
          path: 'service/list',
          name: 'Retail.ServiceList',
          component: Retail.ServiceList,
          props: true,
        },
        {
          path: 'service/archive-list',
          name: 'Retail.ServiceArchiveList',
          component: Retail.ServiceArchiveList,
          props: true,
        },
        {
          path: 'addon/list',
          name: 'Retail.AddonList',
          component: Retail.AddonList,
          props: true,
        },
        {
          path: 'addon/archive-list',
          name: 'Retail.AddonArchiveList',
          component: Retail.AddonArchiveList,
          props: true,
        },
        {
          path: 'service/register',
          name: 'Retail.ServiceRegister',
          component: Retail.ServiceRegister,
          props: true,
        },
        {
          path: 'service/renew',
          name: 'Retail.ServiceRenew',
          component: Retail.ServiceRenew,
          props: true,
        },
        {
          path: 'cart',
          name: 'Retail.ShoppingCart',
          component: Retail.ShoppingCart,
          props: true,
        },
        {
          path: 'card',
          name: 'Retail.Card',
          component: Retail.Card,
          props: true,
        },
        {
          path: 'invoice/detail/:id',
          name: 'Retail.InvoiceDetail',
          component: Retail.InvoiceDetail,
          props: true,
        },
        {
          path: 'affiliate-commission/list',
          name: 'Retail.AffiliateCommissionList',
          component: Retail.AffiliateCommissionList,
          props: true,
        },
        {
          path: 'affiliate-commission/withdraw/:id',
          name: 'Retail.AffiliateCommissionWithdrawDetail',
          component: Retail.AffiliateCommissionWithdrawDetail,
          props: true,
        },
        {
          path: 'friend-voucher/list',
          name: 'Retail.FriendVoucherList',
          component: Retail.FriendVoucherList,
          props: true,
        },
        {
          path: 'collaborator/list',
          name: 'Retail.CollaboratorList',
          component: Retail.CollaboratorList,
        },
        {
          path: 'collaborator/:id',
          name: 'Retail.CollaboratorUpdate',
          component: Retail.CollaboratorUpdate,
        },
        {
          path: 'collaborator/service/list',
          name: 'Retail.CollaboratorServiceList',
          component: Retail.CollaboratorServiceList,
        },
        {
          path: 'collaborator/action/list',
          name: 'Retail.CollaboratorActionList',
          component: Retail.CollaboratorActionList,
        },
        {
          path: 'collaborator/new',
          name: 'Retail.CollaboratorNew',
          component: Retail.CollaboratorNew,
        },
        // {
        //   path: 'security',
        //   name: 'Retail.Security',
        //   component: Retail.Security,
        //   props: true,
        // },
      ],
      beforeEnter: (to, from, next) => {
        next();
      },
    },
  ];

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + window.baseUrl,
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (to.name.indexOf('Admin') === 0) {
      if (store.state.user.role !== 'Admin' && store.state.adminUser.role !== 'Admin') {
        try {
          store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
          if (store.state.user.role !== 'Admin' && store.state.adminUser.role !== 'Admin') {
            next({ name: '404' });
          }
        } catch (error) {
          next({ name: '404' });
        }
      }
    }

    const [subdomain, subdomain2] = window.location.host.split('.');
    if (subdomain === 'manage' || subdomain2 === 'manage') {
      const pathParts = to.path.split('/');
      let brand;
      let mainPath;

      if (pathParts[1] === 'usd' || pathParts[1] === 'sgd' || pathParts[1] === 'aud') {
        // eslint-disable-next-line prefer-destructuring
        brand = pathParts[2];
        // eslint-disable-next-line prefer-destructuring
        mainPath = pathParts[3];
      } else {
        // eslint-disable-next-line prefer-destructuring
        brand = pathParts[1];
        // eslint-disable-next-line prefer-destructuring
        mainPath = pathParts[2];
      }
      if (typeof brand !== 'undefined' && brand === '') {
        next({ name: 'Retail404' });
      } else if (typeof brand !== 'undefined' && mainPath === '404') {
        next();
      } else if (typeof brand !== 'undefined' && mainPath !== '404') {
        if (store.state.brand === false) {
          await router.app.$api.post('validate-store', { brand })
            .then((data) => {
              if (data.data.valid !== true) {
                next({ name: 'Retail404' });
              }
            })
            .catch(() => {
              next({ name: 'Retail404' });
            });
        }

        router.app.$api.defaults.headers.common['X-Gce-Brand'] = brand;

        router.app.$api.interceptors.request.use((config) => {
          const newConfig = config;
          if (store.state.user.role) {
            newConfig.url = newConfig.url.replace('partner/', 'customer/');
            newConfig.url = newConfig.url.replace('admin/', 'customer/');
          } else {
            newConfig.url = newConfig.url.replace('partner/', 'guest/');
            newConfig.url = newConfig.url.replace('admin/', 'guest/');
          }
          if (newConfig.data) {
            newConfig.data.brand = brand;
          } else if (newConfig.url.indexOf('brand=') < 0 && newConfig.url.indexOf('?') > 0) {
            newConfig.url += `&brand=${brand}`;
          } else if (newConfig.url.indexOf('brand=') < 0) {
            newConfig.url += `?brand=${brand}`;
          }
          return newConfig;
        }, (error) => {
          Promise.reject(error);
        });

        await store.dispatch('setBrand', brand);

        const skipSyncStore = [
          'Retail.Guest',
          'Retail.GuestShoppingCart',
          'Retail.ExpressCheckout',
          'Retail.ExpressInvoicePayment',
          'Retail.ExpressSaveCard',
          'Retail.ExpressSaveCardPaypal',
          'Retail.Login',
          'Retail.ForgotPassword',
          'Retail.ResetPassword',
        ];

        if (skipSyncStore.indexOf(to.name) < 0) {
          if (!store.state.appInitialized) {
            try {
              store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
            } catch (error) {
              next({ name: 'Retail.Login', params: { brand } });
            }
          } else {
            const currentToken = store.state.user.auth_token;
            const currentSessionData = JSON.parse(localStorage.getItem('app'));
            if (currentToken !== currentSessionData.user.auth_token) {
              next({
                name: 'Retail.Login',
                params: { brand },
              });
            }
          }

          const authToken = window.btoa(router.app.$store.state.user.auth_token);
          router.app.$api.defaults.headers.common.Authorization = `Basic ${authToken}`;
          if (!store.state.user.auth_token) next({ name: 'Retail.Login', params: { brand } });
        }
        next();
      } else {
        next({ name: 'Retail404' });
      }
    } else {
      if (to.name !== 'Login'
        && to.name !== 'ResetPassword'
        && to.name !== 'RequestNewHosting'
        && to.name !== 'NewHosting'
      ) {
        if (!store.state.appInitialized) {
          try {
            store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
          } catch (error) {
            next({ name: 'Login' });
          }
        }
        const authToken = window.btoa(router.app.$store.state.user.auth_token);
        router.app.$api.defaults.headers.common.Authorization = `Basic ${authToken}`;
        if (!store.state.user.auth_token) next({ name: 'Login' });
      }

      next();
    }
  });
} else if (
  host.indexOf('cp.gocloudeasy.com') === 0
  || host.indexOf('staging.cp.gocloudeasy.com') === 0) {
  const routes = [
    {
      path: '/:partnercode?',
      name: 'CpLogin',
      component: Cp.CpLogin,
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/cp',
      component: Cp.CpBase,
      children: [
        {
          path: '/login-cpanel',
          name: 'CpLoginCpanel',
          component: Cp.CpLoginCpanel,
        },
      ],
    },
  ];

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + window.baseUrl,
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (to.name === null) {
      next({ name: 'CpLogin' });
    } else if (to.name !== 'CpLogin') {
      if (!store.state.appInitialized) {
        try {
          store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
        } catch (error) {
          next({ name: 'CpLogin' });
        }
      }

      const authToken = window.btoa(router.app.$store.state.user.auth_token);
      router.app.$api.defaults.headers.common.Authorization = `Basic ${authToken}`;
      if (!store.state.user.auth_token) next({ name: 'CpLogin' });
    }

    next();
  });
} else if (host.indexOf('login.cldy.com') === 0
  || host.indexOf('staging.login.cldy.com') === 0
  || host.indexOf('login.gocloudeasy.com') === 0
  || host.indexOf('staging.login.gocloudeasy.com') === 0) {
  const routes = [
    {
      path: '/',
      name: 'CpLogin',
      component: Cp.CpLoginCldy,
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/cp',
      component: Cp.CpBase,
      children: [
        {
          path: '/login-cpanel',
          name: 'CpLoginCpanel',
          component: Cp.CpLoginCpanel,
        },
      ],
    },
  ];

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + window.baseUrl,
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    if (to.name === null) {
      next({ name: 'CpLogin' });
    } else if (to.name !== 'CpLogin') {
      if (!store.state.appInitialized) {
        try {
          store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
        } catch (error) {
          next({ name: 'CpLogin' });
        }
      }

      const authToken = window.btoa(router.app.$store.state.user.auth_token);
      router.app.$api.defaults.headers.common.Authorization = `Basic ${authToken}`;
      if (!store.state.user.auth_token) next({ name: 'CpLogin' });
    }

    next();
  });
} else {
  const routes = [
    {
      path: '/',
      name: 'Retail.Guest',
      component: Retail.Guest,
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/guest-cart',
      name: 'Retail.GuestShoppingCart',
      component: Retail.GuestShoppingCart,
      beforeEnter: (to, from, next) => {
        next();
      },
    },
    {
      path: '/express-checkout/:token',
      name: 'Retail.ExpressCheckout',
      props: true,
      component: Retail.ExpressCheckout,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/express-save-card/:token',
      name: 'Retail.ExpressSaveCardPaypal',
      props: true,
      component: Retail.ExpressSaveCardPaypal,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/express-invoice/:id',
      name: 'Retail.ExpressInvoicePayment',
      props: true,
      component: Retail.ExpressInvoicePayment,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/login',
      name: 'Retail.Login',
      component: Retail.Login,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/signup-affiliate',
      name: 'Retail.AffiliateSignup',
      component: Retail.AffiliateSignup,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/forgot-password',
      name: 'Retail.ForgotPassword',
      component: Retail.ForgotPassword,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/reset-password/:token',
      name: 'Retail.ResetPassword',
      component: Retail.ResetPassword,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '/service-transfer/:token',
      name: 'Retail.ServiceTransfer',
      component: Retail.ServiceTransfer,
      props: true,
      beforeEnter: (to, from, next) => {
        store.dispatch('reset');
        next();
      },
    },
    {
      path: '*',
      name: '404',
      component: PageNotFound,
    },
    {
      path: '/refresh',
      name: 'refresh',
      component: Refresh,
    },
    {
      path: '/',
      component: Retail.Base,
      children: [
        {
          path: 'home',
          name: 'Retail.Home',
          component: Retail.Home,
        },
        {
          path: 'business-card-generator',
          name: 'Retail.BusinessCardGenerator',
          component: Retail.BusinessCardGenerator,
        },
        {
          path: 'vcard/list',
          name: 'Retail.VcardList',
          component: Retail.VcardList,
        },
        {
          path: 'vcard/create',
          name: 'Retail.VcardCreate',
          component: Retail.VcardCreate,
        },
        {
          path: 'settings',
          name: 'Retail.Settings',
          component: Retail.Settings,
        },
        {
          path: 'profile',
          name: 'Retail.Profile',
          component: Retail.UserProfile,
        },
        {
          path: 'payment/history',
          name: 'Retail.TopupHistory',
          component: Retail.TopupHistory,
        },
        {
          path: 'payment/tax-invoice-list',
          name: 'Retail.TaxInvoiceList',
          component: Retail.TaxInvoiceList,
        },
        {
          path: 'payment/tax-invoice/:id',
          name: 'Retail.TaxInvoice',
          component: Retail.TaxInvoice,
        },
        {
          path: 'domain/detail/:id',
          name: 'Retail.DomainDetail',
          component: Retail.DomainDetail,
          props: true,
        },
        {
          path: 'domain/list',
          name: 'Retail.DomainList',
          component: Retail.DomainList,
          props: true,
        },
        {
          path: 'domain/list-archive',
          name: 'Retail.DomainListArchive',
          component: Retail.DomainListArchive,
          props: true,
        },
        {
          path: 'domain/register',
          name: 'Retail.DomainRegister',
          component: Retail.DomainRegister,
          props: true,
        },
        {
          path: 'domain/renew',
          name: 'Retail.DomainRenew',
          component: Retail.DomainRenew,
          props: true,
        },
        {
          path: 'domain/transfer',
          name: 'Retail.DomainTransfer',
          component: Retail.DomainTransfer,
          props: true,
        },
        {
          path: 'domain/request-transfer/:id',
          name: 'Retail.DomainRequestTransfer',
          component: Retail.DomainRequestTransfer,
          props: true,
        },
        {
          path: 'domain/default-contact',
          name: 'Retail.DomainDefaultContact',
          component: Retail.DomainDefaultContact,
          props: true,
        },
        {
          path: 'service/detail/:id',
          name: 'Retail.ServiceDetail',
          component: Retail.ServiceDetail,
          props: true,
        },
        {
          path: 'dns/:id',
          name: 'Retail.DnsEditor',
          component: Retail.DnsEditor,
          props: true,
        },
        {
          path: 'service/list',
          name: 'Retail.ServiceList',
          component: Retail.ServiceList,
          props: true,
        },
        {
          path: 'service/archive-list',
          name: 'Retail.ServiceArchiveList',
          component: Retail.ServiceArchiveList,
          props: true,
        },
        {
          path: 'addon/list',
          name: 'Retail.AddonList',
          component: Retail.AddonList,
          props: true,
        },
        {
          path: 'addon/archive-list',
          name: 'Retail.AddonArchiveList',
          component: Retail.AddonArchiveList,
          props: true,
        },
        {
          path: 'service/register',
          name: 'Retail.ServiceRegister',
          component: Retail.ServiceRegister,
          props: true,
        },
        {
          path: 'service/renew',
          name: 'Retail.ServiceRenew',
          component: Retail.ServiceRenew,
          props: true,
        },
        {
          path: 'cart',
          name: 'Retail.ShoppingCart',
          component: Retail.ShoppingCart,
          props: true,
        },
        {
          path: 'card',
          name: 'Retail.Card',
          component: Retail.Card,
          props: true,
        },
        {
          path: 'invoice/detail/:id',
          name: 'Retail.InvoiceDetail',
          component: Retail.InvoiceDetail,
          props: true,
        },
        {
          path: 'affiliate-commission/list',
          name: 'Retail.AffiliateCommissionList',
          component: Retail.AffiliateCommissionList,
          props: true,
        },
        {
          path: 'affiliate-commission/withdraw/:id',
          name: 'Retail.AffiliateCommissionWithdrawDetail',
          component: Retail.AffiliateCommissionWithdrawDetail,
          props: true,
        },
        {
          path: 'friend-voucher/list',
          name: 'Retail.FriendVoucherList',
          component: Retail.FriendVoucherList,
          props: true,
        },
        {
          path: 'collaborator/list',
          name: 'Retail.CollaboratorList',
          component: Retail.CollaboratorList,
        },
        {
          path: 'collaborator/:id',
          name: 'Retail.CollaboratorUpdate',
          component: Retail.CollaboratorUpdate,
        },
        {
          path: 'collaborator/service/list',
          name: 'Retail.CollaboratorServiceList',
          component: Retail.CollaboratorServiceList,
        },
        {
          path: 'collaborator/action/list',
          name: 'Retail.CollaboratorActionList',
          component: Retail.CollaboratorActionList,
        },
        {
          path: 'collaborator/new',
          name: 'Retail.CollaboratorNew',
          component: Retail.CollaboratorNew,
        },
      ],
      beforeEnter: (to, from, next) => {
        next();
      },
    },
  ];

  router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL + window.baseUrl,
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    let affiliateCode = to.query.referral;
    if (affiliateCode) {
      localStorage.setItem('referral', affiliateCode);
      let { goto } = to.query;
      if (goto) {
        goto = goto.replace(/^\/+/, '');
        window.location = `https://www.cldy.com/sg/${goto}`;
      }
    } else {
      const affiliateCodeLs = localStorage.getItem('referral');
      if (affiliateCodeLs) {
        affiliateCode = affiliateCodeLs;
      }
    }

    let brand = 'cldy';

    if (host.indexOf('qoxy') > 0) {
      brand = 'qoxy';
    }

    const mainPath = to.path;
    if (mainPath === '404') {
      next();
    } else if (mainPath !== '404') {
      router.app.$api.defaults.headers.common['X-Gce-Brand'] = brand;

      router.app.$api.interceptors.request.use((config) => {
        const newConfig = config;
        if (store.state.user.role) {
          newConfig.url = newConfig.url.replace('partner/', 'customer/');
          newConfig.url = newConfig.url.replace('admin/', 'customer/');
        } else {
          newConfig.url = newConfig.url.replace('partner/', 'guest/');
          newConfig.url = newConfig.url.replace('admin/', 'guest/');
        }
        if (newConfig.data) {
          newConfig.data.brand = brand;
          if (affiliateCode && newConfig.url.indexOf('referral=') < 0) {
            newConfig.url += `?referral=${affiliateCode}`;
          }
        } else if (newConfig.url.indexOf('brand=') < 0 && newConfig.url.indexOf('?') > 0) {
          newConfig.url += `&brand=${brand}`;
          if (affiliateCode && newConfig.url.indexOf('referral=') < 0) {
            newConfig.url += `&referral=${affiliateCode}`;
          }
        } else if (newConfig.url.indexOf('brand=') < 0) {
          newConfig.url += `?brand=${brand}`;
          if (affiliateCode && newConfig.url.indexOf('referral=') < 0) {
            newConfig.url += `&referral=${affiliateCode}`;
          }
        }
        return newConfig;
      }, (error) => {
        Promise.reject(error);
      });

      await store.dispatch('setBrand', brand);

      const skipSyncStore = [
        'Retail.Guest',
        'Retail.GuestShoppingCart',
        'Retail.ExpressCheckout',
        'Retail.ExpressInvoicePayment',
        'Retail.ExpressSaveCard',
        'Retail.ExpressSaveCardPaypal',
        'Retail.Login',
        'Retail.ForgotPassword',
        'Retail.ResetPassword',
        'Retail.AffiliateSignup',
        'ForgotPassword',
        'ResetPassword',
      ];

      if (skipSyncStore.indexOf(to.name) < 0) {
        if (!store.state.appInitialized) {
          try {
            store.dispatch('syncStore', JSON.parse(localStorage.getItem('app')));
          } catch (error) {
            next({ name: 'Retail.Login', params: { brand } });
          }
        } else {
          const currentToken = store.state.user.auth_token;
          const currentSessionData = JSON.parse(localStorage.getItem('app'));
          if (currentToken !== currentSessionData.user.auth_token) {
            next({
              name: 'Retail.Login',
              params: { brand },
            });
          }
        }

        if (!store.state.user.auth_token) next({ name: 'Retail.Login', params: { brand } });

        const authToken = window.btoa(store.state.user.auth_token);
        router.app.$api.defaults.headers.common.Authorization = `Basic ${authToken}`;
      }
      next();
    } else {
      next({ name: 'Retail404' });
    }
  });
}

export default router;
