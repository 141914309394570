<template>
  <v-row>
    <v-col cols="12">
      <v-row dense align="center">
        <v-col cols="6">
          <v-text-field
            label="Domain"
            v-model="form.domain"
            :error-messages="domainErr"
            :rules="[rules.domain]"
            v-on:blur="processDomain"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <h5>Partner</h5>
          <partner-dropdown
            label="Partner"
            :rules="[rules.required]"
            :single="true"
            :partners.sync="form.partner"
            :nochips="true"
          ></partner-dropdown>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            dense
            label="EPP Code"
            v-model="form.epp"
            :append-icon="showEpp ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required]"
            :type="showEpp ? 'text' : 'password'"
            @click:append="showEpp = !showEpp"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense align="center" v-if="showRegistrationForm">
        <v-col cols="12">
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-row dense>
              <v-col cols="6">
                <h5>Registrant Contact</h5>
                <component
                  v-bind:is="registrantComponent"
                  ref="registrantProfileComp"
                  :forceOrg="forceOrgAdmin"
                  :setNewProfile="registrant"
                ></component>
              </v-col>
            </v-row>
            <v-col class="text-right">
              <v-btn
                text-align="right"
                color="primary"
                @click="checkout()"
                :disabled="checkoutDisabled && !checkoutProcessing"
                :loading="checkoutProcessing"
              >Submit</v-btn>
            </v-col>
          </v-form>
        </v-col>
      </v-row>
      <v-row dense align="center">
        <v-col cols="12">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Domain
                </th>
                <th class="text-left">
                  Partner
                </th>
                <th class="text-left">
                  Requested At
                </th>
                <th class="text-left">
                  Completed At
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="item in pendingList"
                :key="item.id"
              >
                <td>{{ item.domain_name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <p class="red--text" v-if="!item.completed_at === true">Pending</p>
                  <p class="green-text" v-else>Completed at {{ item.completed_at }}</p>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ProfileSg from '../../components/ProfileSg.vue';
import PartnerDropdown from '../../components/PartnerDropdown.vue';

export default {
  name: 'ZeroYearTransferSgnic',
  components: {
    ProfileSg,
    PartnerDropdown,
  },
  created() {
    this.$api.post('admin/domain/zero-year-transfer-sgnic-list', {})
      .then((response) => {
        this.$set(this, 'pendingList', response.data.list);
      });
  },
  data() {
    return {
      pendingList: [],
      tld: null,
      form: {
        domain: '',
        epp: '',
        product_id: null,
        product_term: null,
        tld: null,
        auto_renew: true,
        ns1: '',
        ns2: '',
        ns3: '',
        ns4: '',
        ns5: '',
        registrant: {},
        partner: null,
      },
      currentContactMethod: 'defaultContact',
      years: [
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
      ],
      showEpp: false,
      defaultContact: null,
      registrant: null,
      admin: null,
      tech: null,
      billing: null,
      domainErr: null,
      tld_list: [],
      tld_value: '',
      tldPriceList: [],
      terms: [],
      checkDomainLoading: false,
      checkDomainInvalid: true,
      checkoutProcessing: false,
      checkoutDisabled: false,
      showPriceTable: false,
      showRegistrationForm: true,
      registrantComponent: ProfileSg,
      forceOrgAdmin: false,
      successMessage: 'Transfer successfully.',
      valid: false,
      rules: {
        required: (value) => !!value || 'This field is required.',
        isNumeric: (value) => this.$underscore.isFinite(value) || 'This field must be numeric.',
        domain: (value) => (/^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?/i).test(value) || 'This field must be valid domain name.',
        nameserver: (value) => (/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/i).test(value) || this.$underscore.isEmpty(value) || 'This field must be valid hostname.',
      },
    };
  },
  methods: {
    processDomain() {
      if (this.form.domain.indexOf('.') > 0) {
        this.tld = `.${this.form.domain.split(/\./).slice(1).join('.')}`;
        this.changeTld();
      }
    },
    checkDomain() {
      this.showRegistrationForm = true;
      this.domainErr = '';
      this.checkDomainLoading = true;
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            yearPrice = `${yearPrice} $${term.price}`;
            this.terms.push({
              text: `${index} ${yearPrice}`,
              value: term.term_id,
            });
          });
        }
      });

      this.$api.post('partner/domain/check-availability', this.form).then(() => {
        this.loadRegistrationForm();
      })
        .catch(() => {
          this.domainErr = 'The domain is not available.';
        })
        .finally(() => {
          this.checkDomainLoading = false;
        });
    },
    reloadTerm() {
      this.$set(this, 'terms', []);
      this.$underscore.map(this.tldPriceList, (tld) => {
        if (tld.tld_id === this.form.tld) {
          this.$underscore.map(tld.terms, (term, index) => {
            this.form.product_term = tld.terms[1].term_id;
            let yearPrice = index === '1' ? 'Year' : 'Years';
            yearPrice = `${yearPrice} $${term.price}`;
            this.terms.push({
              text: `${index} ${yearPrice}`,
              value: term.term_id,
            });
          });
        }
      });
    },
    loadRegistrationForm() {
      this.showRegistrationForm = true;
    },
    checkout() {
      this.checkoutProcessing = true;
      this.checkoutDisabled = true;
      if (this.$refs.form.validate()) {
        this.form.registrant = this.$refs.registrantProfileComp.getProfileData();
        this.$api.post('admin/domain/zero-year-transfer-sgnic', this.form)
          .then(() => {
            this.$store.dispatch('addAlert', {
              id: Date.now(),
              type: 'success',
              message: this.successMessage,
            });
            this.$emit('reloadActivity');
            this.reset();
          })
          .catch((error) => {
            if (error.response) {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.response.data.message || error.response.data,
              });
            } else {
              this.$store.dispatch('addAlert', {
                id: Date.now(),
                type: 'error',
                message: error.message,
              });
            }
          })
          .finally(() => {
            this.checkoutProcessing = false;
            this.checkoutDisabled = false;
          });
      } else {
        this.checkoutProcessing = false;
        this.checkoutDisabled = false;
      }
    },
    copyContact(from, to) {
      if (this.currentContactMethod !== from) {
        this.currentContactMethod = from;
      }

      if (from === 'defaultContact') {
        this[to] = this.defaultContact;
        const sgTld = [
          'sg',
          'com.sg',
        ];

        const auTld = [
          'au',
          'com.au',
          'org.au',
          'net.au',
          'id.au',
        ];

        if (sgTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
              this[to].special_field2 = this.defaultContact.personal_identity_no;
            } else {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }

        if (auTld.indexOf(this.tld_value) >= 0) {
          if (this.defaultContact) {
            if (this.$underscore.isEmpty(this.defaultContact.corporate_no)) {
              this[to].special_field1 = 'p';
            } else {
              this[to].special_field1 = 'o';
              this[to].special_field2 = this.defaultContact.corporate_no;
            }
          }
        }
      } else if (from === 'profile') {
        this.$api.get('partner/profile').then((response) => {
          this[to] = {};
          const sgTld = [
            'com.sg',
          ];
          const auTld = [
            'com.au',
            'org.au',
            'net.au',
          ];
          if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
            this[to].special_field1 = 'o';
          } else {
            this[to].special_field1 = 'p';
          }
          this[to].first_name = response.data.first_name;
          this[to].last_name = response.data.last_name;
          this[to].company_name = response.data.company_name;
          this[to].email = response.data.email;
          this[to].address1 = response.data.address1;
          this[to].city = response.data.city;
          this[to].state = response.data.state;
          this[to].country = response.data.country;
          this[to].postal_code = response.data.postal_code;
          this[to].phone = response.data.phone;
          this[to].phone_country_code = response.data.phone_country_code;
        });
      } else {
        this[to] = this.$refs[`${from}ProfileComp`].getProfileData();
      }
    },
    clearContact(to) {
      this[to] = {
        first_name: '',
        last_name: '',
        email: '',
        company_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        country: 'SG',
        postal_code: '',
        phone_country_code: '',
        phone: '',
        fax_country_code: '',
        fax: '',
        special_field1: 'p',
        special_field2: '',
      };
      const sgTld = [
        'com.sg',
      ];
      const auTld = [
        'com.au',
        'org.au',
        'net.au',
      ];
      if (sgTld.indexOf(this.tld_value) >= 0 || auTld.indexOf(this.tld_value) >= 0) {
        this[to].special_field1 = 'o';
      }
    },
    reset() {
      this.$router.go(0);
    },
    changeTld() {
      this.showRegistrationForm = true;
      this.domainErr = null;
      if (this.tld === '.sg') {
        this.registrantComponent = ProfileSg;
        this.forceOrgAdmin = false;
      } else if (this.tld === '.com.sg') {
        this.registrantComponent = ProfileSg;
        this.forceOrgAdmin = true;
      } else if (this.tld === '.org.sg') {
        this.registrantComponent = ProfileSg;
        this.forceOrgAdmin = true;
      } else if (this.tld === '.edu.sg') {
        this.registrantComponent = ProfileSg;
        this.forceOrgAdmin = true;
      }
    },
  },
  watch: {
    'form.domain': {
      handler(domain) {
        if (this.rules.domain(domain) !== true) {
          this.checkDomainInvalid = true;
        } else {
          this.checkDomainInvalid = false;
          this.domainErr = null;
        }
      },
    },
    'form.tld': {
      handler() {
        this.reloadTerm();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
